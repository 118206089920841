@import 'assets/styles/_colors.scss';
$first-column-width: 7.5rem;
$border-color: rgba(34, 36, 38, 0.15);
$left: 30rem;

.comparables-table-container {
  position: relative;
  border: 0.07142rem solid $border-color;
  border-radius: 0.2857rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 1rem;

  .shadow-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    border-radius: 0.2857rem;
  }

  .scrollable-wrapper {
    position: relative;
    overflow: auto;
  }

  .benchmark-header-name {
    color: inherit;
  }
  .benchmark-header-name:hover {
    text-decoration: underline;
  }

  .filter-icon {
    &:hover {
      border-radius: 0.03rem;
      background: $gray-light-v2;
      outline: 0.5rem solid $gray-light-v2; /* Red outline */
    }
  }

  .row-sub-header {
    div {
      width: 100%;
      display: inline-flex;

      span:nth-child(1) {
        width: 80%;
      }
      span:nth-child(2) {
        width: 20%;
        float: right;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    svg {
      font-size: 1.7rem;
      padding: 0.15rem;
    }

    .action-icons {
      .filter-icon,
      .sort-icon {
        border-radius: 0.2857rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;

        &:hover,
        &.active {
          background-color: $white;
          box-shadow:
            0 0.2857rem 0.4285rem $black-shadow,
            0 0 0.8571rem $black-shadow;
        }

        &.active {
          color: $orange;
        }
      }

      .sort-icon {
        svg {
          transform: translateX(-0.2142rem);
        }
      }
    }
  }

  .comparables-table {
    border: none !important;
    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 3;
        padding: 0 0.5rem;
        background-color: $white-smoke;

        &:nth-of-type(1) {
          min-width: 30rem;
          width: 30rem;
          z-index: 4;
          left: 0;
        }

        &:nth-of-type(2) {
          position: sticky;
          z-index: 4;
          left: $left;
          border-right: 0.07142rem solid $border-color;
        }

        span {
          display: inline-flex;
        }

        .filter-icon {
          font-size: 1.7rem;
          display: block;
          cursor: pointer;
        }

        .column-header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;

          .header-name-container {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            width: 80%;

            .column-identifier {
              width: 80%;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
            }

            .column-cancel {
              margin-left: 1rem;
              height: 1.4rem;
              width: 1.4rem;
              border-radius: 0.7rem;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:hover {
                box-shadow: 0 0 0 0.2rem $red-soft;
              }

              svg {
                color: $red-soft;
                transform: scale(1.3);
                cursor: pointer;
              }
            }
          }

          .move-icons {
            position: absolute;
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .arrow {
              cursor: pointer;
              border-radius: 50%;

              &:hover {
                background: $gray-light-v2;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 12rem;
          padding: 0.5rem 1rem;

          &:nth-of-type(1) {
            min-width: 30rem;
            width: 30rem;

            position: sticky;
            left: 0;
            background-color: $white; // to keep the column top of the scrolling
            z-index: 2;
          }

          &:nth-of-type(2) {
            min-width: 12rem;
            position: sticky;
            left: $left;
            z-index: 2;
            background-color: $white; // to keep the column top of the scrolling
            border-right: 0.07142rem solid $border-color;
          }

          &:hover {
            background: $gray-very-light-v7 !important;
          }
        }

        &:hover {
          background: $white-smoke !important;
        }
      }
    }

    .row-header {
      td {
        background-color: $white-smoke !important;
      }
    }

    .row-filter-icon {
      cursor: pointer;
      vertical-align: middle;
    }
  }
}
