@import 'assets/styles/_colors.scss';

.comparables-table-filter-popup {
  width: 18rem;

  &.row-layout {
    width: 48rem;

    .comparables-table-buttons-row {
      justify-content: center;
      gap: 1rem;

      .ui.button {
        width: 10rem;
      }
    }
  }

  .inner-container {
    &.column-layout {
      & > * {
        padding: 1rem;
      }

      .filter-type-box {
        background: $white-smoke;
      }

      .filter-item {
        &:not(:nth-of-type(1)) {
          border-top: 0.0714rem solid $grayish-magenta-light !important;
        }
      }
    }

    &.row-layout {
      display: flex;
      align-items: flex-start;
      padding: 1rem;

      & > * {
        width: 12rem;
        margin-left: 0.75rem;
      }

      label {
        display: flex;
        align-items: flex-end;
        font-weight: bolder;
        padding-top: 0.5rem;
        margin-left: 0;
        width: 8rem;
      }

      &:not(:nth-of-type(1)) {
        border-top: 0.0714rem solid $grayish-magenta-light !important;
      }
    }

    .filter-item {
      .dropdown-select-wrapper {
        width: 100%;
      }

      .field:nth-of-type(2) {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }

      .grid {
        .field {
          margin-bottom: 0;
          margin-top: 0.75rem;
        }
      }

      .react-datepicker-wrapper {
        input {
          margin: 0;
        }
      }
    }

    .fluid-btn {
      width: 100%;
      font-size: 0.875rem;
      padding: 0.65625rem 1.09375rem;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      align-items: center;
      vertical-align: bottom;
      text-align: center;
      overflow: hidden;
      position: relative;
      font-weight: bolder;
      background: $white;

      &.add {
        color: $orange;

        &:hover {
          background: $orange-very-light-grayish;
          color: $orange;
        }
      }

      &.delete {
        color: $red-soft;

        &:hover {
          background-color: $red-very-pale;
        }
      }

      svg {
        transform: scale(1.5);
      }
    }
  }

  .comparables-table-buttons-row {
    display: flex;
    justify-content: space-between;

    .ui.button {
      font-size: 0.875rem;
      padding: 0.65625rem 1.09375rem;
      border-radius: 0.4285rem;
      line-height: 1.15;

      &:nth-of-type(1) {
        border: 0.0714rem solid $orange;
        color: $orange;
        background: $white;
      }

      &:nth-of-type(2) {
        background: $orange;
        color: $white;
        margin-right: 0;
      }
    }
  }
}
