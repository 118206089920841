@import 'assets/styles/_colors.scss';

.table-of-parameters-answer-tab-box {
  border-radius: 0.857rem;
  margin-bottom: 1rem;
  &.no-columns {
    .table-name {
      position: relative;
      margin: 0.7rem 0;
      left: 1.5rem;
      font-size: 1.4rem;
      top: 0;

      .table-info {
        color: $red-soft;
      }
    }
  }
  .ui.segment {
    padding: 1rem !important;
  }
  .ui.secondary.pointing.menu {
    height: 5.5rem !important;
  }
  .iteration-btn-delete {
    margin-left: 0.457rem;
    cursor: pointer;
    svg {
      width: 1.243rem;
      height: 1.243rem;
      position: relative;
      top: 0.05rem;
    }
    span {
      font-size: 1rem;
      margin-left: 0.429rem;
    }
  }
}

.add-iteration-btn {
  position: relative;
  top: 0.3rem;
  cursor: pointer;

  svg {
    width: 1.429rem;
    height: 1.429rem;
  }
  p {
    margin-top: -0.3rem;
  }
}

.table-name {
  position: absolute;
  left: 1.2rem;
  font-size: 1.4rem;
  top: 0.8rem;
}
