@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.row-expansion-template {
  tr {
    overflow: hidden;
    display: block;
    position: relative;
    left: -1rem;
    td {
      &:first-of-type {
        width: 19em;
      }
      &:nth-of-type(2) {
        width: 10.3em;
      }
      &:nth-of-type(3) {
        width: 9.5em;
      }
      &:nth-of-type(4) {
        width: 15em;
        span {
          position: relative;
          left: 0.3rem;
        }
      }
      &:nth-of-type(5) {
        width: 13em;
      }
    }
  }

  &.condensed {
    tr {
      display: flex;
      width: 100%;
      left: 0;
      td {
        width: 25%;
        &:first-of-type {
          width: 48%;
        }
        &:nth-of-type(2) {
          position: relative;
          top: 0.4rem;
        }
        &:nth-of-type(3) {
          top: 0.1rem;
        }
        &:last-of-type {
          top: 0.2rem;
        }
        .drag-drop-icon {
          top: 0.5rem;
        }
      }

      .ui.checkbox.inner-row-checkbox {
        top: 0.4rem;
      }
    }
  }

  .drag-drop-icon {
    color: $gray-dark-v2;
    left: 10rem;
    top: 0.4rem;
    position: relative;
    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .ui.checkbox.inner-row-checkbox {
    left: 11.5rem;
    top: 0.35rem;
  }

  .badge-container {
    position: relative;
    left: 1.2rem;
  }

  .draggable-item.dragging {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
  }

  .discussion-name {
    color: $blue;
  }
}

.p-submenu-list {
  position: relative;
  top: -10rem !important;
  max-height: 20rem;
  overflow-y: auto;
}

@media (min-width: $xxl-device-min-width) and (max-width: $xxxl-device-min-width-v2) {
  .row-expansion-template {
    &.condensed {
      td {
        &:first-of-type {
          width: 55% !important;
        }
        &:nth-of-type(3) {
          position: relative;
          left: 1.3rem;
        }
        &:last-of-type {
          position: relative;
          left: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: $xxxl-device-min-width-v2) and (max-width: $big-monitors-min-width) {
  .row-expansion-template {
    &.condensed {
      td {
        &:first-of-type {
          width: 50% !important;
        }
        &:nth-of-type(3) {
          position: relative;
          left: 1.6rem;
        }
      }
    }
  }
}

@media (min-width: $big-monitors-min-width) and (max-width: $tv-device-min-width) {
  .row-expansion-template {
    &.condensed {
      td {
        &:first-of-type {
          width: 48% !important;
        }
        &:nth-of-type(3) {
          position: relative;
          left: 2rem;
        }
      }
    }
  }
}

@media (min-width: $tv-device-min-width) {
  .row-expansion-template {
    &.condensed {
      td {
        &:first-of-type {
          width: 46% !important;
        }
        &:nth-of-type(2) {
          position: relative;
          left: -0.2rem;
        }
      }
    }
  }
}
.custom-popup {
  max-height: 20rem;
  overflow: auto;
}

